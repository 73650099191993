<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteUser($event, null , true)"
    />
    <vs-popup
      :active.sync="popupActiveDetails"
      title="تفاصيل المستخدم"
    >
      <form>
        <div class="vx-row">
          <img
            v-lazy="user.image_url"
            class="m-auto custom-image-size"
          >
        </div>

        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الاسم : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ user.first_name }}</h4>
            <br>
          </div>
        </div>

        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الكنية : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ user.last_name }}</h4>
            <br>
          </div>
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الجنس : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ user.gender }}</h4>
            <br>
          </div>
        </div>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الإيميل : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ user.email }}</h4>
            <br>
          </div>
        </div>
        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row mt-12">
          <div class="vx-col sm:w-1/4 mb-4">
            <vs-button
              @click="popupActiveDetails=false"
              type="filled"
              ycolor="success"
            >
              حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
    <store-popup
      :user="user"
      @created="userCreated"
      ref="storePopupRef"
    />
    <update-popup
      :user="user"
      @updated="userUpdated"
      ref="updatePopupRef"
    />
  </div>
</template>

<script>

import AgTable from '../shared-components/ag-grid/AgTable';
import AgTableBtnCell from '../shared-components/ag-grid/AgTableBtnCell.vue';
import AgTableImageCell from '../shared-components/ag-grid/AgTableImageCell';
import utilities from '../utilities/index';
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import AgButton from "@/app/shared/shared-components/ag-grid/AgButton";
import StorePopup from "@/app/shared/shared-components/StoreUserPopup";
import UpdatePopup from "@/app/shared/shared-components/UpdateUserPopup";

const userRepo = SharedRepositoryFactory.get('userRepository');

export default {
  name: "DashboardUsersMain",
  components: {
    UpdatePopup,
    AgTable,
    StorePopup
  },

  data() {
    return {
      gridOptions: null,
      gridApi: null,
      rowData: [],
      user: {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        image_url: '',
        password_confirmation: '',
        gender: '',
        roles: [],
        permissions: [],
        store_ids: [],
        stores: []
      },
      genderOptions: [
        {
          name: 'ذكر',
          value: 'Male'
        },
        {
          name: 'أنثى',
          value: 'Female'
        }
      ],
      imagePreview: null,
      imageRequired: false,
      dialogTitle: '',
      isLoading: true,
      popupActiveDetails: false,
    };
  },

  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          checkboxSelection: true,
        },
        {
          headerName: 'الصورة',
          field: 'image_url',
          cellRendererFramework: AgTableImageCell,
          cellRendererParams: {
            width: '30%'
          }
        },

        {
          headerName: 'الاسم',
          field: 'first_name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الكنية',
          field: 'last_name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإيميل',
          field: 'email',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الأدوار',
          cellRendererFramework: AgButton,
          field: 'id',
          filter: false,
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'عرض الأدوار',
            type: 'gradient',
            click(id) {
              self.rowClickedRoles(id);
            }
          }
        },
        {
          headerName: 'الصلاحيات',
          cellRendererFramework: AgButton,
          field: 'id',
          filter: false,
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'عرض الصلاحيات',
            type: 'gradient',
            click(id) {
              self.rowClickedPermissions(id);
            }
          }
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteUser(id, rowIndex);
            },
            editRecord: function (record) {
              self.editRecord(record);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ['view', 'edit', 'delete']
          },
        },
      ];
    },
    rowClickedRoles(id) {
      this.$router.push(`/users/${id}/roles`);
    },
    rowClickedPermissions(id) {
      this.$router.push(`/users/${id}/permissions`);
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.user = record;
    },
    deleteUser(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      userRepo.deleteUser(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllUsers();
          else {
            this.rowData.splice(index, 1);
            this.rerender();
          }
        }
        this.isLoading = false;
      });
    },
    fetchAllUsers() {
      userRepo.fetchAllUsers(this.$route.params.key).then((response) => {
        this.rowData = response;
        this.isLoading = false;
      });
    },
    userUpdated(user) {
      let index = this.rowData.findIndex(item => item.id === user.id);
      this.rowData[index] = user;
      this.gridApi.setRowData(this.rowData);
    },
    userCreated(user) {
      this.rowData.push(user);
    },
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    createRecord() {
      utilities.initObjectMembers(this.user);
      this.$refs.storePopupRef.open();
    },
    editRecord(user) {
      this.user = user;
      this.$refs.updatePopupRef.open();
    }
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    const $this = this;
    this.$watch(
      () => this.$route.params,
      async () => {
        $this.fetchAllUsers();
      },
      {immediate: true}
    );
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
