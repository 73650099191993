<template>
  <div>
    <tm-popup
      :fullscreen="true"
      :submit="onSubmit"
      ref="popup"
      title="إضافة مستخدم"
    >
      <vs-row class="mb-5">
        <div class="vx-col  w-full text-center">
          <h4 class="my-5">
            الصورة الشخصية
          </h4>
          <file-pond-component
            :file="user.image_url"
            @fileDeleted="fileDeleted"
            @fileUploaded=" fileUploaded"
            :rules="{required: false}"
            project="website"
          />
        </div>
      </vs-row>
      <vs-row
        vs-align="center"
        vs-justify="center"
        vs-type="flex"
      >
        <vs-row
          vs-align="center"
          vs-justify="center"
          vs-type="flex"
        >
          <vs-col
            class="mb-5 mr-5"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
            vs-w="5"
          >
            <tm-input
              class="w-full"
              name="الاسم"
              type="text"
              v-model="user.first_name"
              validation-rules="required"
            />
          </vs-col>
          <vs-col
            class="mb-5 mr-5"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
            vs-w="5"
          >
            <tm-input
              class="w-full"
              name="الكنية"
              type="text"
              v-model="user.last_name"
              validation-rules="required"
            />
          </vs-col>
        </vs-row>
        <vs-row
          vs-align="center"
          vs-justify="center"
          vs-type="flex"
        >
          <vs-col
            class="mb-5 mr-5"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
            vs-w="5"
          >
            <tm-select
              :options="genderOptions"
              :reduce="name => name.value"
              class="w-full"
              label="name"
              name="الجنس"
              v-model="user.gender"
              validation-rules="required"
            />
          </vs-col>
          <vs-col
            class="mb-5 mr-5"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
            vs-w="5"
          >
            <tm-input
              :validation-rules="{ required: true , digits: 10, regex: /^09\d{8}$/}"
              class="w-full"
              name="رقم الموبايل"
              type="number"
              v-model="user.mobile"
            />
          </vs-col>
        </vs-row>
        <vs-row
          vs-align="center"
          vs-justify="center"
          vs-type="flex"
        >
          <vs-col
            class="mb-5 mr-5"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
            vs-w="5"
          >
            <tm-input
              class="w-full"
              name="الايميل"
              type="text"
              v-model="user.email"
              validation-rules="required|email"
            />
          </vs-col>
          <vs-col
            class="mb-5 mr-5"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
            vs-w="5"
          >
            <tm-input
              class="w-full"
              name="كلمة السر"
              type="password"
              v-model="user.password"
              validation-rules="required"
            />
          </vs-col>
        </vs-row>
        <vs-row
          vs-align="center"
          vs-justify="center"
          vs-type="flex"
        >
          <vs-col
            class="mb-5 mr-5"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
            vs-w="5"
          >
            <tm-input
              class="w-full"
              name="تأكيد كلمة السر"
              type="password"
              v-model="user.password_confirmation"
              validation-rules="required"
            />
          </vs-col>
          <vs-col
            class="mb-5 mr-5"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
            vs-w="5"
          >
            <tm-select
              :multiple="true"
              :options="roles"
              :reduce="role => role"
              @input="checkSelectedRole"
              class="w-full"
              label="name_ar"
              name="الدور"
              v-model="selectedRoles"
              validation-rules="required"
            />
          </vs-col>
        </vs-row>
        <vs-row
          vs-align="center"
          vs-justify="center"
          vs-type="flex"
        >
          <vs-col
            class="mb-5"
            v-if="isStoreAdmin"
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
            vs-w="6"
          >
            <tm-select
              :options="stores"
              :reduce="store => store.id"
              :validation-rules="{ required: this.isStoreAdmin }"
              class="w-full"
              label="name"
              name="المحل"
              :multiple="true"
              v-model="user.store_ids"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col
            vs-align="center"
            vs-justify="center"
            vs-lg="12"
            vs-sm="12"
            vs-type="flex"
            vs-xs="12"
            class="mb-5"
          >
            <vs-button
              @click="openPopup"
              color="primary"
              icon="icon-plus"
              icon-pack="feather"
              size="small"
              type="filled"
            >
              إضافة دور
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row
          vs-align="center"
          vs-justify="center"
          vs-type="flex"
        >
          <vs-col
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
            vs-w="10"
          >
            <vs-card>
              <vs-row
                vs-align="center"
                vs-justify="center"
                vs-type="flex"
              >
                <vs-col
                  class="mb-5 mr-5"
                  vs-align="center"
                  vs-justify="center"
                  vs-type="flex"
                  vs-w="5"
                >
                  <tm-select
                    :options="projects"
                    :reduce="option => option"
                    @input="setProjectFilter"
                    class="w-full"
                    label="showName"
                    name="المشروع"
                    v-model="projectForRole"
                    validation-rules=""
                  />
                </vs-col>
                <vs-col
                  class="mb-5 mr-5"
                  vs-align="center"
                  vs-justify="center"
                  vs-type="flex"
                  vs-w="5"
                >
                  <tm-select
                    :options="projectPermissions"
                    :reduce="option => option"
                    class="w-full"
                    label="name_ar"
                    name="الصلاحيات"
                    v-model="permission"
                    validation-rules=""
                  />
                </vs-col>
              </vs-row>
              <vs-row class="mb-5">
                <div
                  v-for="(permission,index) in addedPermissions"
                  :key="index"
                >
                  <vs-chip
                    @click="deletePermission(index,permission)"
                    closable
                    color="primary"
                    transparent
                  >
                    {{ permission.name_ar }}
                  </vs-chip>
                </div>
              </vs-row>
              <vs-row>
                <vs-col
                  vs-align="center"
                  vs-justify="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-type="flex"
                  vs-xs="12"
                >
                  <vs-button
                    @click="addPermission()"
                    color="primary"
                    icon="icon-plus"
                    icon-pack="feather"
                    size="small"
                    type="filled"
                  >
                    اضافة الصلاحية
                  </vs-button>
                </vs-col>
              </vs-row>
            </vs-card>
          </vs-col>
        </vs-row>
      </vs-row>
    </tm-popup>
    <tm-popup
      class="showPopup"
      :submit="storeRole"
      title="إضافة دور"
      ref="addRolePopup"
    >
      <div class="vx-row justify-center">
        <div class="vx-col w-full">
          <tm-input
            name="الاسم"
            type="text"
            placeholder="الاسم"
            validation-rules="required"
            v-model="role.name"
            class="w-full"
          />
        </div>
      </div>
      <div class="vx-row justify-center">
        <div class="vx-col w-full">
          <tm-input
            name="الاسم بالعربي"
            type="text"
            placeholder="الاسم بالعربي"
            validation-rules="required"
            v-model="role.name_ar"
            class="w-full"
          />
        </div>
      </div>
      <div class="vx-row justify-center">
        <div class="vx-col w-full">
          <tm-input
            name="الوصف"
            type="text"
            placeholder="الوصف"
            validation-rules="required"
            v-model="role.desc"
            class="w-full"
          />
        </div>
        <vs-card>
          <vs-row
            vs-align="center"
            vs-justify="center"
            vs-type="flex"
          >
            <vs-col
              class="mb-5 mr-5"
              vs-align="center"
              vs-justify="center"
              vs-type="flex"
              vs-w="5"
            >
              <tm-select
                :options="projects"
                :reduce="option => option"
                @input="setProjectFilter"
                class="w-full"
                label="showName"
                name="المشروع"
                v-model="project"
                validation-rules=""
              />
            </vs-col>
            <vs-col
              class="mb-5 mr-5"
              vs-align="center"
              vs-justify="center"
              vs-type="flex"
              vs-w="5"
            >
              <tm-select
                :options="projectPermissions"
                :reduce="option => option"
                class="w-full"
                label="name_ar"
                name="الصلاحيات"
                v-model="permission"
                validation-rules=""
              />
            </vs-col>
          </vs-row>
          <vs-row class="mb-5">
            <div
              v-for="(permission,index) in addedPermissionsForRole"
              :key="index"
            >
              <vs-chip
                @click="deletePermission(index,permission,true)"
                closable
                color="primary"
                transparent
              >
                {{ permission.name_ar }}
              </vs-chip>
            </div>
          </vs-row>
          <vs-row>
            <vs-col
              vs-align="center"
              vs-justify="center"
              vs-lg="12"
              vs-sm="12"
              vs-type="flex"
              vs-xs="12"
            >
              <vs-button
                @click="addPermission(true)"
                color="primary"
                icon="icon-plus"
                icon-pack="feather"
                size="small"
                type="filled"
              >
                اضافة الصلاحية
              </vs-button>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
    </tm-popup>
  </div>
</template>

<script>
import TmPopup from "@/app/shared/shared-components/TmPopup";
import TmInput from "@/app/shared/shared-components/TmInput";
import TmSelect from "@/app/shared/shared-components/TmSelect";
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import {RepositoryFactory} from "../../ecommerce/repositories/ecommerceRepositoryFactory";
import FilePondComponent from "@/app/shared/shared-components/FilePondComponent";
import utilities from '@/app/shared/utilities/index';

const roleRepo = SharedRepositoryFactory.get('roleRepository');
const userRepo = SharedRepositoryFactory.get('userRepository');
const storeRepo = RepositoryFactory.get('store');
const permissionRepo = SharedRepositoryFactory.get('permissionRepository');

export default {
  name: "StorePopup",
  data() {
    return {
      isStoreAdmin: false,
      selectedRoles: [],
      stores: [],
      permission: '',
      project: '',
      projectForRole: '',
      projectPermissions: [],
      permissions: [],
      addedPermissions: [],
      addedPermissionsForRole: [],
      permissionId: [],
      roles: [],
      permissionsFilter: [],
      projects: [
        {projectName: 'website', showName: 'صلاحيات الموقع'},
        {projectName: 'ecommerce', showName: 'صلاحيات المتجر الالكتروني'},
        {projectName: 'donation', showName: 'صلاحيات التبرعات'},
        {projectName: 'events', showName: 'صلاحيات الحفلات'},
        {projectName: 'raven', showName: 'صلاحيات رافن'},
        {projectName: 'barcode', showName: 'صلاحيات الباركود'},
        {projectName: 'default', showName: 'صلاحيات لوحة التحكم'},
        {projectName: 'rihabAlHabeb', showName: 'صلاحيات رحاب الحبيب'}
      ],
      role: {
        name: '',
        name_ar: '',
        desc: '',
        permissions: []
      },
      genderOptions: [
        {
          name: 'ذكر',
          value: 'Male'
        },
        {
          name: 'أنثى',
          value: 'Female'
        }
      ],
    };
  },

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  methods: {
    onSubmit() {
      this.$refs.popup.loading();
      this.selectedRoles.forEach(item => {
        this.user.roles.push(item.id);
      });
      this.addedPermissions.forEach(item => {
        this.user.permissions.push(item.id);
      });
      this.storeUser();
    },
    storeRole() {
      this.addedPermissionsForRole.forEach(item => {
        this.role.permissions.push(item.id);
      });
      roleRepo.storeRoleAll(this.role).then((response) => {
        this.$refs.addRolePopup.endLoading();
        if (response.code === 201) {
          this.$refs.addRolePopup.close();
          utilities.initObjectMembers(this.role);
          this.project = '';
          this.addedPermissionsForRole = [];
          this.fetchAllRoles();
          this.fetchAllPermissions();

        }
      });
    },
    fileUploaded(file) {
      this.user.image_url = file;
    },
    fileDeleted() {
      this.user.image_url = null;
    },
    openPopup() {
      this.permission = '';
      this.$refs.addRolePopup.open();
    },
    setProjectFilter(project) {
      this.projectPermissions = [];
      this.permissions.forEach(item => {
        if (item.project_name === project.projectName)
          this.projectPermissions.push(item);
      });
    },
    storeUser() {
      this.user = this.lodash.omit(this.user, ['contact_numbers', 'birthdate']);
      userRepo.storeUserAll(this.user).then((response) => {
        this.$refs.popup.endLoading();
        if (response.code === 200) {
          this.$refs.popup.close();
          this.$emit("created", response.data);
        }
      });
    },
    checkSelectedRole(selected) {
      this.isStoreAdmin = false;
      selected.forEach(item => {
        if (item.name === 'store_admin')
          this.isStoreAdmin = true;
      });
    },
    open() {
      this.init();
      this.$refs.popup.open();
    },
    init() {
      this.addedPermissions = [];
      this.projectPermissions = [];
      this.permission = '';
      this.project = '';
      this.projectForRole = '';
      this.permission = '';
      this.selectedRoles = [];
      this.isStoreAdmin = false;
    },
    fetchAllStores(isArchived = false) {
      storeRepo.fetchAllStores(isArchived).then((data) => {
        this.stores = data;
      });
    },
    fetchAllRoles() {
      roleRepo.fetchAllRoles().then((data) => {
        this.roles = data;
      });
    },
    fetchAllPermissions() {
      permissionRepo.fetchAllPermissions().then((data) => {
        this.permissions = data;
      });
    },
    deletePermission(index, permission, isNewRole = false) {
      if (isNewRole)
        this.addedPermissionsForRole.splice(index, 1);
      else
        this.addedPermissions.splice(index, 1);
    },
    addPermission(isNewRole = false) {
      if (isNewRole)
        this.addedPermissionsForRole.push(this.permission);
      else
        this.addedPermissions.push(this.permission);
    }
  },
  created() {
    this.fetchAllRoles();
    this.fetchAllPermissions();
    this.fetchAllStores();
  },
  components: {
    TmPopup,
    TmInput,
    TmSelect,
    FilePondComponent
  },
};
</script>
<style>
.vs-con-input .vs-inputx {
  height: 39px;
}

.showPopup {
  z-index: 55000 !important;
}
</style>
